@charset "utf-8";
/* CSS Document */

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,400,600&display=swap');

/*font-family: 'IBM Plex Sans', sans-serif;*/

*,
*::before,
*::after {
	box-sizing:border-box;
	margin:0;
	padding:0;
}

body {
	background:grey;
	font-family: 'IBM Plex Sans', sans-serif;
	line-height:1.2;
	font-size:16px;
}

h1 {
    margin: 1.5rem 0 0.75rem 0;
}

h2 {
    font-size:2.2rem;
    font-weight:400;
    color:$colorYellow;
    line-height:1;
    margin: 1.5rem 0 0.75rem 0;
}

h3 {
    font-size:1.6rem;
    font-weight:400;
    color:$colorYellow;
    line-height:1;
    margin: 1.5rem 0 0.75rem 0;
}

h4 {
    font-size:1.4rem;
    font-weight:400;
    color:$colorYellow;
    line-height:1;
    margin: 1.5rem 0 0.75rem 0;
}

p {
    color:white;
    font-weight:200;
    font-size:1.4rem;
    margin: 8px 0;
    line-height: 2rem;

    a {
        text-decoration: none;
        color: inherit;
    }
}

ul, ol {
  list-style-position: inside;
  padding-left: 1rem;
}

li {
  color:white;
  font-weight:200;
  font-size: 1.3rem;
  margin: 0.2rem 0;
}

blockquote {
  padding: 20px 30px;
  font-style: italic;
}

q {
  font-style: italic;
}

hr {
  margin: 5px 0;
}

table {
  color:white;
  font-weight:200;
  font-size:1.2rem;
  
  caption {
  }
  td, th {
  }
}


/*
=================
     FOOTER
=================
*/

footer {
	background-color:$colorDarkBlueGreen;
	padding:3rem 2rem;
	text-align:center;
	color:white;
	font-weight:400;
}

footer ul {
	list-style:none;
	margin-bottom:2rem;
}

footer a {
	font-weight:200;
	text-decoration:none;
	color:rgba(255,255,255,0.4);
	margin:1rem;
	display:block;
	font-size:1.2rem;
}

footer a:hover,
footer a:visited {
	color:rgba(255,255,255,1);
}

footer ul li:first-child {
	margin-bottom:.6rem;
	border-bottom:1px solid rgba(255,255,255,0.5);
	padding-bottom:10px;
	font-size:1.5rem;
	font-weight:200;
}

@media (min-width:1024px) {

	.footer-wrapper {
		display:grid;
		grid-template-columns:repeat(4,1fr);
		text-align: left;
		grid-gap:20px;
		max-width:1200px;
		margin:0 auto;
	}

}


/*
====================================================
                   SCROLL TO TOP
====================================================
*/

.scrollToTop {
	font-weight:600;
	color:white;
	width:75px;
	height:75px;
	background:linear-gradient($colorHeader,#283694);
  	align-items:center;
	justify-content: center;
	text-align:center;
	border-radius:50%;
	text-decoration:none;
	line-height:1;
	padding:20px 10px;
	position:fixed;
	right:2rem;
	bottom:2rem;
	box-shadow: 0px 4px 2px rgba(0,0,0,0.5);
	display: none;
	transition:background-color ease .3s, background-position ease .3s;
  	z-index:99;
}

.scrollToTop:visited {

}

.scrollToTop:hover,
.scrollToTop:focus {

}
