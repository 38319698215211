/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

@charset "utf-8";
/* CSS Document */

/*
=================
     HEADER
=================
*/

$transitionToSmallTime: 0.5s;
$transitionToLargeTime: 0.25s;

.header-small {
  header {
    height: 75px;
    transition: $transitionToSmallTime;
  }
  .version {
    font-size: 0.8rem;
    padding: 0.2rem;
    width: 108px;
    transition: $transitionToSmallTime;
  }
  .donations {
    font-size: 0.8rem;
    padding: 0.2rem;
    width: 108px;
    transition: $transitionToSmallTime;
  }
  nav {
    li {
        font-size: 15px;
    }
  }
  
  @media (min-width: 1024px) {
    header {
      .logo {
        max-width: 150px;
        top: 14px;
        transition: $transitionToSmallTime;
      }
      .version {
        top: 8px;
      }
      .donations {
        top: 8px;
      }
    }
    #header-spacer {
      height: 95px;
      transition: $transitionToSmallTime;
    }
    nav::before {
      top: 16px;
      transition: $transitionToSmallTime;
    }
  }
  @media (max-width:1023px) {
    header {
      .logo {
        position: relative;
        top: -41px;
        max-width: 100px;
      }
      .version {
        position: relative;
        top: -190px;
        right: -110px;
      }
      .donations {
        position: relative;
        top: -190px;
        right: -210px;
      }
    }
    #header-spacer {
      height: 140px;
      transition: $transitionToSmallTime;
    }
  }
}

.header-large {
  .logo {
    max-width: 265px;
    top: 40px;
    transition: $transitionToLargeTime;
  }
  .version {
    top: 55px;
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 150px;
    transition: $transitionToLargeTime;
  }
  .donations {
    top: 55px;
    font-size: 1.2rem;
    padding: 0.5rem;
    width: 150px;
    transition: $transitionToLargeTime;
  }
  nav {
    li {
        font-size: 16px;
    }
  }
  
  @media (min-width: 1024px) {
    #header-spacer {
      height: 140px;
      transition: $transitionToLargeTime;
    }
    header {
      height: 150px;
      transition: $transitionToLargeTime;
    }
    nav::before {
      top: 26px;
      transition: $transitionToLargeTime;
    }
  }
  
  @media (max-width: 1023px) {
    #header-spacer {
      height: 430px;
      transition: $transitionToLargeTime;
    }
    header {
      height: 430px;
      transition: $transitionToLargeTime;
    }
  
  }
}

@media (min-width: 1024px) {
    #header-spacer {
      height: 150px;
    }
}

@media (max-width: 1023px) {
    #header-spacer {
      height: 430px;
    }
}

header {
    background-color:$colorHeaderBg;
    padding:4rem 2rem;
    width: 100%;
    position: fixed;
    z-index: 1000;
        
    .logo {
        width:80vw;
        margin:0 auto;
        max-width:320px;
        cursor: pointer;
    }
    
    .logo-left {
        display:none;
    }
    
    .version {
        padding:1rem;
        border:1px solid #8c8c8c;
        border-radius:2rem;
        width:100px;
        text-align: center;
        font-size:1.2rem;
        font-weight:600;
        margin:4rem auto 1rem auto;
    }

    .donations {
        padding:1rem;
        border:1px solid #8c8c8c;
        border-radius:2rem;
        width:100px;
        text-align: center;
        font-size:1.2rem;
        font-weight:600;
        margin:4rem auto 1rem auto;
    }
}


@media (min-width:600px) {

    header {
        .logo-centered {
            display:none;
        }
    
        .logo-left {
            display:block;
        }
    
        .logo {
            max-width:500px;
        }
    }
}

@media (min-width:1024px) {

    header {
        margin-top:0;
        height:150px;
        padding:0;
            
        nav::before {
            content:'';
            display:block;
            width:640px;
            position:absolute;
            height:35px;
            background:url(../images/nav-bracket.svg);
            background-repeat:no-repeat;
            background-size:cover;
            top:26px;
            right:-2rem;
        }
  
        .donations {
            position:absolute;
            right:12rem;
            top:55px;
            padding:0.5rem;
            width:150px;
            margin:0;
        }
    
        .version {
            position:absolute;
            right:2rem;
            top:55px;
            padding:0.5rem;
            width:150px;
            margin:0;
        }
    
        .version a {
            text-decoration: none;
            color: inherit;
        }
    
        .donations a {
            text-decoration: none;
            color: inherit;
        }
    
        .logo {
            max-width:265px;
            position:absolute;
            left:2rem;
            top:40px;
        }
    }
}

.nav-toggle {
	display: none;
}

.nav-toggle-label {
	position:absolute;
	top:0;
	left:0;
	padding-left: 1.75em;
	padding-right:1.75em;
	height:80px;
	width:100%;
	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

/*burger menu background*/
.nav-toggle-label::before {
	content:'';
	display:block;
	width:80px;
	height:80px;
	background-color:red;
	position:absolute;
	left :0;
	background:-o-linear-gradient(315deg,$colorHeader,$colorHeaderGradient);
	background:linear-gradient(135deg,$colorHeader,$colorHeaderGradient);
}

.nav-toggle-label:hover {
	cursor:pointer;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
	display:block;
	background:white;
	width:1.5em;
	height:3px;
	border-radius:2px;
	position:relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
	content:'';
	position:absolute;
}

.nav-toggle-label span::before {
	  bottom:7px;
}

.nav-toggle-label span::after {
	top:7px;
}

nav {
	position:absolute;
	text-align: left;
	background-color:$colorHeaderBg;
	width:100%;
	top:80px;
	left:0;
	-webkit-transform: scale(1,0);
	    -ms-transform: scale(1,0);
	        transform: scale(1,0);
	-webkit-transition: -webkit-transform 400ms ease-in-out;
	transition: -webkit-transform 400ms ease-in-out;
	-o-transition: transform 400ms ease-in-out;
	transition: transform 400ms ease-in-out;
	transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
	-webkit-transform-origin: top;
	    -ms-transform-origin: top;
	        transform-origin: top;
	z-index:999;
	
    > ul {
        margin:0;
        padding:0;
        list-style: none;
        background-color: $colorHeaderGradient;
        
        > li {
            padding:0.6em 1em;
            border-bottom:1px solid rgba(255,255,255,0.3);
            font-size: 16px;
            position: relative;
            
            > a {
                line-height:2.4em;
                &::after {
                    bottom: 7px;
                }
            }
            
            &.nav-hovered {
                > ul {
                    display: block;
                    position: absolute;
                }
            }
            
            @media (min-width: 1024px) {
                > ul {
                    top: 46px;
                }   
            }
            @media (max-width: 1023px) {
                > ul {
                    top: 56px;
                    left: 80px;
                }
            }

            > ul {
                display: none;
                list-style: none;
                background-color: $colorBlueHeader;
                top: 46px;
                border-radius: 10px;
                padding: 0;
                box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.75), 0px -4px 14px 0px rgba(0,0,0,0.25);
                z-index: 100;

                @media (max-width:1023px) {
                    padding: 10px;
                }
                
                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    position: absolute;
                    height: 35px;
                    background: url(../images/nav-dropdown-bracket.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    top: -8px;
                    left: 0;
                }
                
                > li {
                    padding: 0.2em .5em;
                    border-bottom:1px solid rgba(255,255,255,0.3);
                    font-size: 16px;
                    min-width: 160px;

                    &:last-child {
                        border-bottom: none;
                    }
                    
                    @media (max-width:1023px) {
                        padding: 12px 22px;
                    }
                    
                    > a {
                        line-height: 1em;
                        color: $colorHeader;
                    }
                }
            }
        }
    }
    
    a {
        text-decoration: none;
        font-size: 1.2rem;
        color:white;
        text-transform: uppercase;
        display:block;
        opacity:0;
        -webkit-transition:opacity 150ms ease-in-out;
        -o-transition:opacity 150ms ease-in-out;
        transition:opacity 150ms ease-in-out;
    }
    
    a:hover {
        color:#fff;
    }

}

.nav-toggle:checked ~ nav {
	-webkit-transform:scale(1,1);
	    -ms-transform:scale(1,1);
	        transform:scale(1,1);
}

.nav-toggle:checked ~ nav a {
	opacity:1;
	-webkit-transition:opacity 250ms ease-in-out 250ms;
	-o-transition:opacity 250ms ease-in-out 250ms;
	transition:opacity 250ms ease-in-out 250ms;
}

/*check for css grid support*/
  
@media screen and (min-width:1024px) {
	.nav-toggle-label {
		display: none;
	}
  
	nav {
		all:unset;
		width:570px;
		display:block;
		position: absolute;
		left:auto; /*has to be reset for IE*/
		height:auto;
		right:4rem;
		bottom:-20px;
		z-index:2;
		transform:scale(1,1);
		
        > ul {
            display:-webkit-box;
            display:-ms-flexbox;
            display:flex;
            -webkit-box-align: stretch;
                -ms-flex-align: stretch;
                    align-items: stretch;
            background:none;
            
          > li {
              margin:0;
              padding:0;
              border-bottom:none;
              position: relative;
              
              &:first-child a {
                  margin-right:130px;
              }
              
          }
        }
      
      
        a {
            opacity:1;
            position:relative;
            font-size:0.8em;
            font-weight:400;
            width:100%;
            color:black;
            padding:.5rem 1rem
        }
        
        a:hover {
            color:black;
        }
        
        /*link underline*/      
        a::before {
            content:'';
            display:block;
            height:3px;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
        }
    
        /*Link animated underline*/   
        a::after {
            content:'';
            display:block;
            height:3px;
            background:red;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            -webkit-transform: scale(1,0);
                -ms-transform: scale(1,0);
                    transform: scale(1,0);
            -webkit-transform-origin:bottom;
                -ms-transform-origin:bottom;
                    transform-origin:bottom;
            -webkit-transition:-webkit-transform ease-in-out 250ms;
            transition:-webkit-transform ease-in-out 250ms;
            -o-transition:transform ease-in-out 250ms;
            transition:transform ease-in-out 250ms;
            transition:transform ease-in-out 250ms, -webkit-transform ease-in-out 250ms;
        }
      
        a:hover::after {
            -webkit-transform:scale(1,1);
                -ms-transform:scale(1,1);
                    transform:scale(1,1);
            -webkit-transform-origin:bottom;
                -ms-transform-origin:bottom;
                    transform-origin:bottom;
        }
	}
  
	
	/*maintain state*/
	#home nav .home a::before,
	#media nav .media a::before,
	#insights nav .insights a::before,
	#contact nav .contact a::before {
		background:red;
	}
}
