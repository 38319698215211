#portfolio-page {

  #portfolio-slider {
    a {
        text-decoration: none;
        color: inherit;
    }

    .portfolio-slide-frame {
        padding: 0 20px;
        //display: none;
    }
    .portfolio-slide-frame.slick-current {
        display: block;
    }
    .portfolio-slide {
        overflow: hidden;
        background-color: white;
        box-shadow: 0 5px 15px rgba(0,0,0,.08);
    }
    .portfolio-slide img {
        width: 100%;
    }
    .portfolio-slide-body {
        padding: 40px;
    }
    .portfolio-slide-body p, .portfolio-slide-body li {
        color: black;
    }

  }
  #portfolio-nav {
      margin: 0 40px;
      .portfolio-nav-frame {
          background: white;
          margin: 20px;
          overflow: hidden;
      }
      .portfolio-nav-slide {
          height: 130px;
      }
      img {
          width: 90%;
          margin: 0 auto;
      }
  }
  
  .portfolio-image-slider {
    margin: 0 40px;
  }
  
  .header-image {
    float: right;
    max-width: 300px;
    margin: 0 0 20px 20px;
  }
  
  .slick-prev:before, .slick-next:before {
    color: $colorDarkBlueGreen;
  }
  @media screen and (min-width: 800px) {
    
  }
}

.testimoniallist {
    color: white;
    font-size: 1.4rem;
    font-weight: 200;
    text-decoration: none;
    padding-bottom: 1ex;
    padding-top: 1ex;

    a {
        text-decoration: none;
        color: inherit;
    }
  
    dt {
        font-weight: 400;
    }
    dd {
        margin-bottom : 1ex;
        margin-left : 2em;
    }
}
