.donorlist {
    color: white;
    font-size: 1.4rem;
    font-weight: 200;
    text-decoration: none;
    padding-bottom: 1ex;
    padding-top: 1ex;
    
    list-style-type: none;

    li:before {
        content: '- ';
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

.sponsorlist {
    color: white;
    font-size: 1.4rem;
    font-weight: 200;
    text-decoration: none;
    padding-bottom: 1ex;
    padding-top: 1ex;

    a {
        text-decoration: none;
        color: inherit;
    }
  
    dt {
        font-weight: 400;
    }
    dd {
        margin-bottom : 1ex;
    }
}

.dbox-donation-button {
        padding-top:0.5rem;
        padding-bottom:0.5rem;
        padding-left:1.5rem;
        padding-right:1.5rem;
        border:1px solid #8c8c8c;
        border-radius:2rem;
        width:100px;
        text-align: center;
        font-size:1.4rem;
        font-weight:500;
        color:white;
        text-decoration: none;
        background: #5a6fba;
}
